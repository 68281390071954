import { NextPage } from 'next';
import Banner404Image from 'public/images/404_hero.svg';
import PageNotFoundTextImage from 'public/images/page_not_found_text.svg';
import { paths } from 'constants/paths';
import Link from 'next/link';
import { LinkBox, Button } from '@chakra-ui/react';
import { LogCategories, sendClickLog } from 'libs/gtag';
import ErrorPage from '../components/Page/ErrorPage';

const Custom404: NextPage = () => (
  <ErrorPage
    errorImage={Banner404Image}
    errorMessageImage={PageNotFoundTextImage}
    message="お探しのページが見つかりませんでした"
  >
    <LinkBox
      onClick={() => {
        sendClickLog({
          category: LogCategories.NOT_FOUND,
          label: 'ホームに戻る',
        });
      }}
    >
      <Link href={paths.root} passHref>
        <Button variant="outline">ホームに戻る</Button>
      </Link>
    </LinkBox>
  </ErrorPage>
);

export default Custom404;
