import ShizaiLogo from 'components/Shared/ShizaiLogo';
import { Text, Flex, Box, Img } from '@chakra-ui/react';
import { ReactNode } from 'react';

const ErrorPage = ({
  children,
  errorImage,
  errorMessageImage,
  message,
}: {
  children: ReactNode;
  errorImage: string;
  errorMessageImage: string;
  message: string | ReactNode;
}) => (
  <Flex
    flexDirection="column"
    w={{ base: '100%', md: '100%', lg: '22.75rem' }}
    align="center"
    margin="4rem auto 15rem"
  >
    <Box textAlign="center">
      <ShizaiLogo />
    </Box>
    <Box mt="3.8125rem" />
    <Img
      src={errorImage}
      w={{
        base: '18.75rem',
        md: '18.75rem',
        lg: '1005',
      }}
    />
    <Box mt="2rem" />
    <Img
      src={errorMessageImage}
      w={{
        base: '18.75rem',
        md: '18.75rem',
        lg: '1005',
      }}
    />
    <Box mt="2.4375rem" />
    <Text textStyle="body1" align="center">
      {message}
    </Text>
    <Box mt="1.5rem" />
    <Box textAlign="center">{children}</Box>
  </Flex>
);

export default ErrorPage;
